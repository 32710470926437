import {ClientReviewFieldsFragment, ReviewSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {ClientReview, ReviewSection} from '../types/review-section.types'

export function parseReviewSection(section: ReviewSectionFieldsFragment): ReviewSection {
  return {
    __type: 'ReviewSection',
    id: section.id,
    plot: section.plot!,
    title: section.title!,
    description: section.description,
    reviews: parseReviewCollection(section.reviews),
    callToActionTitle: section.callToActionTitle,
  }
}

export function parseReviewCollection(items: ClientReviewFieldsFragment[]): ClientReview[] {
  return items.map(item => parseReview(item as ClientReviewFieldsFragment)) ?? []
}

function parseReview(review: ClientReviewFieldsFragment): ClientReview {
  return {
    comment: review.comment!,
    author: review.author!,
    platform: review.platform,
    rating: review.rating,
  }
}
